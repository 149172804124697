import cobalt2 from "monaco-themes/themes/Cobalt2.json";
import cobalt from "monaco-themes/themes/Cobalt.json";
import dracula from "monaco-themes/themes/Dracula.json";
import monokai from "monaco-themes/themes/Monokai.json";
import solarizedDark from "monaco-themes/themes/Solarized-dark.json";
import pastdark from 'monaco-themes/themes/Pastels on Dark.json'
import active4d from 'monaco-themes/themes/Active4D.json'
import birds from 'monaco-themes/themes/Birds of Paradise.json'


export function initAllThemes(monaco) {
    monaco.editor.defineTheme("cobalt2", cobalt2);
    monaco.editor.defineTheme("cobalt", cobalt);
    monaco.editor.defineTheme("dracula", dracula);
    monaco.editor.defineTheme("monokai", monokai);
    monaco.editor.defineTheme("sdark", solarizedDark);
    monaco.editor.defineTheme("pastdark", pastdark);
    monaco.editor.defineTheme("active4d", active4d);
    monaco.editor.defineTheme("birds", birds);
}

export function themeData() {
    return [
        ["cobalt2", "Cobalt 2"],
        ["cobalt", "Cobalt"],
        ["monokai", "Monokai"],
        ["dracula", "Dracula"],
        ["sdark", "Solarized Dark"],
        ["pastdark", "Pastels on Dark"],
        ["active4d", "Active 4d"],
        ["birds", "Birds of Paradise"],
    ]
}

