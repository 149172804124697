import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CodeWrapper from "./CodeWrapper";
import { initSocket } from "../lib/Socket";
import AllMembers from "./AllMembers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Editor = () => {
  // TAKING OUT THE STATE PARAMETERS
  const params = useLocation().state;
  const username = params?.username;
  const roomid = params?.roomid;

  // STATE TO SHOW THE LOADING SCREEN UNTIL SOCKET IS CONNECTED
  const [isLoading, setIsLoading] = useState(true);
  // ALL CURRENT MEMBERS OF ROOMID
  const [allMembers, setallMembers] = useState([]);

  const [isSidebarHidden, setIsSidebarHidden] = useState(false);

  // REFERENCE TO THE SOCKET VARIABLE
  const socketRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    // IF THE STATE PARAMS ARE NOT SET
    // GO BACK TO HOMEPAGE
    if (!username || !roomid) {
      navigate("/");
      return;
    }

    // INITIALIZING AND CONNECTING TO THE SOCKET
    socketRef.current = initSocket();
    socketRef.current.connect();
    setIsLoading(false);
    socketRef.current.on("connect", () => {
      // REQUESTING TO JOIN A SPECIFIC ROOM
      socketRef.current.emit("joinning", { groupid: roomid, username });
    });

    // WHEN SOMEONE JOINS OR LEAVES NOTIFICATION COMES
    socketRef.current.on("someone joined", ({ newmember, allmembers }) => {
      if (newmember) toast.success(newmember + " joined the room.");
      setallMembers(allmembers);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.off("connect");
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [navigate, roomid, username]);

  // FUNCTION TO LEAVE THE ROOM
  // DISCONNECTION OF SOCKET IS NECESSARY
  const leaveRoom = () => {
    socketRef.current.off("connect");
    socketRef.current.disconnect();
    socketRef.current = null;
    navigate("/");
  };

  // COPY TO CLIPBOARD THE URL OF THE ROOM-ID
  // FOR OTHERS TO JOIN THE SAME ROOM
  const copyToClipboard = () => {
    const { protocol, hostname, port } = window.location;
    let homepageURL = `${protocol}//${hostname}`;

    if (
      port &&
      ((protocol === "http:" && port !== "80") ||
        (protocol === "https:" && port !== "443"))
    ) {
      homepageURL += `:${port}`;
    }

    homepageURL += "/" + roomid;

    navigator.clipboard
      .writeText(homepageURL)
      .then(() => {
        toast.success("Room Link copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy Room Link to clipboard.");
      });
  };

  const collapseSidebar = () => {
    document.querySelector(".aside").classList.toggle("collapsed");
    setIsSidebarHidden(!isSidebarHidden);
  };

  const sidebarWidth = isSidebarHidden ? "0%" : "18%";
  const editorWidth = isSidebarHidden ? "100%" : "82%";

  // IF THE SOCKET IS STILL CONNECTING
  if (isLoading) return <div>LOADING</div>;
  return (
    <>
      <div className="container-fluid p-0">
        <div className="d-flex p-0">
          <div
            style={{ width: sidebarWidth, transition: "width 0.3s" }}
            className="aside"
          >
            <span onClick={collapseSidebar}></span>
            <AllMembers allMembers={allMembers} />
            <div className="btns-container">
              <button onClick={copyToClipboard} className="success">
                Copy Room Link
              </button>
              <button onClick={leaveRoom} className="danger">
                Leave Room
              </button>
            </div>
          </div>
          <div
            style={{ width: editorWidth, transition: "width 0.3s" }}
            className="p-0 h-100"
          >
            <CodeWrapper socketRef={socketRef} roomid={roomid} />
          </div>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
      </div>
    </>
  );
};

export default Editor;
