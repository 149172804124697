import axios from "axios";
import React, { useRef, useState } from "react";
import { Editor } from "@monaco-editor/react";
import { initAllThemes, themeData } from "../lib/Themes";

const Compiler = () => {
  //   const [language, setLanguage] = useState("cpp");
  const [theme, setTheme] = useState("cobalt2");
  const [code, setCode] = useState("//write your cpp code here");
  const editorRef = useRef(null);

  const editorDidMount = async (editor, monaco) => {
    editorRef.current = editor;
    initAllThemes(monaco);
    monaco.editor.setTheme(theme);
    await editor.updateOptions({
      fontSize: 16,
      lineHeight: 1.5,
      fontFamily: "Fira code",
      padding: {
        top: 36,
      },
    });
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  const handleChange = (e) => {
    setCode(e);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/execute", { code });
      console.log(response.data);
      document.getElementById("output").innerHTML = response.data.output;
    } catch (error) {
      console.error(error);
      document.getElementById("output").innerHTML = error;
    }
  };

  const allThemes = themeData();

  return (
    <div className="container-fluid p-0">
      <div style={{ height: "91vh" }} className="row m-0 p-0">
        <div className="col-md-8 p-0 m-0">
          <div className="code-wrapper">
            <div className="theme-dropdown">
              <label htmlFor="thmdrp">Theme: </label>
              <select id="thmdrp" value={theme} onChange={handleThemeChange}>
                {allThemes.map((themeOption, index) => (
                  <option key={index} value={themeOption[0]}>
                    {themeOption[1]}
                  </option>
                ))}
              </select>
            </div>
            <Editor
              height="90vh"
              language={"cpp" || "javascript"}
              value={code}
              theme={theme}
              onMount={editorDidMount}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-4 p-0 m-0 position-relative">
          <pre id="output" className="p-2"></pre>
          <button className="mx-2 my-0 success" onClick={handleSubmit}>
            Compile And Run
          </button>
        </div>
      </div>
    </div>
  );
};

export default Compiler;
