import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { generateRandomString } from "../lib/Helper";

const Home = () => {
  // TAKING ROOMID PARAM FROM URL
  const { roomId } = useParams();
  const navigate = useNavigate();

  // DEFINED STATES FOR ROOMID, USERNAME, BTN-DISABLING
  const [roomid, setroomid] = useState(
    roomId ? roomId : generateRandomString()
  );
  const [username, setusername] = useState("");
  const [btndisable, setbtndisable] = useState(true);

  useEffect(() => {
    // IF ROOMID AND USERNAME BOTH ARE FILLED
    // SUBMIT BTN WILL BE ENABLED
    if (roomid !== "" && username !== "") setbtndisable(false);
    else setbtndisable(true);
  }, [roomid, username]);

  // FOR NAVIGATING TO EDITOR PAGE
  // ALONG WITH ROOMID AND USERNAME
  // IN THE STATE
  const goToEditorPage = () => {
    navigate("/editor", { state: { roomid, username } });
  };

  return (
    <div className="home-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <form className="col-lg-5 col-md-8">
            <div className="mb-3">
              <label htmlFor="RoomID" className="form-label">
                Room ID
              </label>
              <input
                value={roomid}
                onChange={(e) => {
                  setroomid(e.target.value);
                }}
                className="form-control"
                id="RoomID"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Your Name
              </label>
              <input
                value={username}
                onChange={(e) => {
                  setusername(e.target.value);
                }}
                className="form-control"
                id="username"
              />
            </div>
            <div className="d-flex">
              <button
                type="button"
                className="success mx-auto"
                disabled={btndisable}
                onClick={goToEditorPage}
              >
                Enter Into Collab
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
