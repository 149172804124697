import { io } from 'socket.io-client';

// const URL = 'http://192.168.29.243:4000';
// const URL = 'https://realtime-backend.adaptable.app/';


export const initSocket = () => {
    return io("/", {
        autoConnect: false,
        transports: ['websocket'] // Specify WebSocket transport
    });
} 