import React from "react";

const About = () => {
  return (
    <div className="container about-wrapper">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h2>About Real-Collab</h2>
          <p>
            Hello, I am <span className="my-name">IMON RAJ</span>, a CS student
            and welcome to Real-Collab, an innovative real-time code
            collaboration project.
          </p>
          <p>
            Real-Collab is the result of my passion for coding and my desire to
            create a platform that facilitates seamless collaboration among
            developers.
          </p>
          <p>
            As a developer myself, I understand the importance of working
            together efficiently and in real-time, and that's exactly what
            Real-Collab offers.
          </p>
          <p>
            Utilizing cutting-edge technologies such as ReactJS, Node.js, and
            Socket.io, Real-Collab provides a dynamic and interactive coding
            environment where you can collaborate with others in real-time.
          </p>
          <p>
            But that's not all! Real-Collab also features an online C++
            compiler, allowing you to write, compile, and run C++ code directly
            within the platform. Whether you're working on algorithmic problems,
            coding challenges, or experimenting with C++ code, our integrated
            compiler provides a convenient and efficient way to test and debug
            your code.
          </p>
          <p>
            So join Real-Collab today and experience the joy of collaborative
            coding, along with the added convenience of an online C++ compiler.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
