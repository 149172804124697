export const generateRandomString = (length = 9) => {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 1; i <= length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
        if (i % 3 === 0 && i !== length) result += '-';
    }

    return result;
};
