import React from "react";

const AllMembers = ({ allMembers }) => {
  return (
    <div className="members-wrapper">
      <h4>Joined Members</h4>
      {allMembers.map((e, index) => (
        <div className="single-member" key={index}>
          {e}
        </div>
      ))}
    </div>
  );
};

export default AllMembers;
