import React, { Component } from "react";
import { Editor } from "@monaco-editor/react";
import { initAllThemes, themeData } from "../lib/Themes";

class CodeWrapper extends Component {
  constructor({ socketRef, roomid }) {
    super();
    this.state = {
      language: "javascript",
      isProgrammaticChange: false,
      theme: "cobalt2", // Default theme
    };
    this.editorRef = React.createRef();
    this.socket = socketRef.current;
    this.socket.on("freshdata", this.handleSync);
    this.socket.on("supply", this.handleGiveCodeSupply);
    this.roomid = roomid;
  }

  componentDidMount() {}

  componentWillUnmount() {
    // CLEARING THE EVENTS ON SOCKET
    this.socket.off("freshdata", this.handleSync);
    this.socket.off("supply", this.handleGiveCodeSupply);
  }

  onChange = (newValue) => {
    if (!this.state.isProgrammaticChange) {
      // IF USER HAS TYPED THEN SEND IT TO SERVER
      this.socket.emit("freshdata", newValue);
    }
  };

  editorDidMount = async (editor, monaco) => {
    this.editorRef.current = editor;
    initAllThemes(monaco);
    monaco.editor.setTheme(this.state.theme);
    await editor.updateOptions({
      fontSize: 17,
      lineHeight: 2,
      fontFamily: "Fira code",
      padding: {
        top: 36,
      },
    });
    // ASKING FOR CODE SYNC AFTER JOINING FOR THE FIRST TIME
    this.socket.emit("give supply", this.roomid);
  };

  handleSync = (value) => {
    if (this.editorRef.current) {
      this.setState({ isProgrammaticChange: true }, () => {
        this.editorRef.current.setValue(value);
        console.log("came something", value);
        this.setState({ isProgrammaticChange: false });
      });
    }
  };

  handleGiveCodeSupply = () => {
    console.log("someone asking for supply");
    const code = this.editorRef.current.getValue();
    console.log("sending supply", code);
    this.socket.emit("freshdata", code);
  };

  setTheme = (theme) => {
    this.setState({ theme });
  };

  render() {
    const { language, theme } = this.state;
    const allthemes = themeData();

    return (
      <div className="code-wrapper">
        <div className="theme-dropdown">
          <label htmlFor="thmdrp">Theme: </label>
          <select
            id="thmdrp"
            value={theme}
            onChange={(e) => this.setTheme(e.target.value)}
          >
            {allthemes.map((e, i) => {
              return (
                <option key={i} value={e[0]}>
                  {e[1]}
                </option>
              );
            })}
          </select>
        </div>
        <Editor
          height="91.7vh"
          language={language || "javascript"}
          value={""}
          theme={theme}
          onChange={this.onChange}
          onMount={this.editorDidMount}
        />
      </div>
    );
  }
}

export default CodeWrapper;
