
import { Route, Routes } from 'react-router-dom';
import './App.css';
import About from './components/About';
import Compiler from './components/Compiler';
import Editor from './components/Editor';
import Home from './components/Home';
import Nav from './components/Nav';

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path='/:roomId' element={<Home />}></Route>
        <Route path='/' element={<Home />}></Route>
        <Route path='/editor' element={<Editor />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/compiler' element={<Compiler />}></Route>
      </Routes>
    </div>
  );
}

export default App;
