import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../imgs/head.png";

const Nav = () => {
  return (
    <nav className="container-fluid">
      <div className="container nav-container">
        <img className="logo" src={logo} alt="" />
        <ul className="links">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => {
                return isActive ? "active" : "";
              }}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about">About</NavLink>
          </li>
          <li>
            <NavLink to="/compiler">CPP Compiler</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
